* {
  scrollbar-width: thin;
}

*::-webkit-scrollbar {
  height: 10px;
  width: 10px;
}

*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #d3d3d3;
}

body {
  margin: 0;
}
